<template>
  <div class="homePage">
    <div class="topBanner">
      <div class="context">
        <p>中西融汇   文理复合</p>
        <p>The fusion of Chinese and Western culture and science</p>
      </div>
      <ul class="context_ul">
        <li class="active">01</li>
        <li>02</li>
        <li>03</li>
        <li>04</li>
        <li>05</li>
      </ul>
    
      <div class="leftImg">
        <img ref="scrollContainer1" src="../assets/img/lvyou299.png" />
      </div>
    </div>
    <div class="contentBanner">
      <div class="news_info">
        <div class="news_info_title">
          <p>新闻资讯<span>NEWS</span></p>
          <span class="seeMore">查看更多</span>
        </div>
        <div class="center">
          <div class="news_info_slide">
            <img src="../assets/img/3.jpg" alt="">
            <div class="news_info_slide_item">旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会</div>
            <div class="news_info_slide_item">旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会</div>
            <div class="news_info_slide_item">旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会</div>
          </div>
          <div class="news_info_center">
            <img src="../assets/img/3.jpg" alt="">
          </div>
          <div class="news_info_slide">
            <img src="../assets/img/3.jpg" alt="">
            <div class="news_info_slide_item">旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会</div>
            <div class="news_info_slide_item">旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会</div>
            <div class="news_info_slide_item">旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会</div>
          </div>
        </div>
      </div>
      <div class="notice">
        <div class="notice_title">
          <p>通知公告<span>ANNOUNCEMENT</span></p>
          <span class="seeMore">查看更多</span>
        </div>
        <div class="notice_module">
          <div class="noticeItem">
            <div class="noticeItemDate">02/20</div>
            <div class="times">2020</div>
            <div class="noticeContent">
              旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会
            </div>
          </div>
          <div class="noticeItem">
            <div class="noticeItemDate">02/20</div>
            <div class="times">2020</div>
            <div class="noticeContent">
              旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会
            </div>
          </div>
          <div class="noticeItem">
            <div class="noticeItemDate">02/20</div>
            <div class="times">2020</div>
            <div class="noticeContent">
              旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会
            </div>
          </div>
          <div class="noticeItem active">
            <div class="noticeItemDate">02/20</div>
            <div class="times">2020</div>
            <div class="noticeContent">
              旅科院召开全体贯培生返校前教育暨疫情防控主题年级大会
            </div>
          </div>
        </div>
      </div>

      <div class="science">
        <div class="science_title">
          <p>科学研究<span>SCIENTIFIC RESEARCH</span></p>
          <span class="seeMore">查看更多</span>
        </div>
        <div class="science_piece">
          <img src="../assets/img/c1.png" alt="" />
          <p>国<br />家<br />级</p>
          <p>NATIONAL LEVEL</p>
        </div>
        <div class="science_piece">
          <img src="../assets/img/c2.png" alt="" />
          <p>省<br />部<br />级</p>
          <p>PROVINCIAL</p>
        </div>
        <div class="science_piece">
          <img src="../assets/img/c3.png" alt="" />
          <p>地<br />厅<br />级</p>
          <p>BASEMENT LEVEL</p>
        </div>
        <div class="science_piece">
          <img src="../assets/img/c4.png" alt="" />
          <p>重<br />点<br />专<br />项<br />研<br />究<br />任<br />务</p>
          <p>KEY SPECIAL RESEARCH TASKS</p>
        </div>
      </div>

      <div class="media">
        <div class="media_title">
          <p>媒体报道<span>MEDIA COVERAGE</span></p>
          <span class="seeMore">查看更多</span>
        </div>
        <ul>
          <li class="active">
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
          <li>
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
          <li>
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
          <li>
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
          <li>
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
          <li>
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
          <li>
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
          <li>
            <p class="title">【首都经济报道】</p>
            <div class="bottom">
              <span class="ellipsis">推动北京率先实现服务贸易高质量发展</span>
              <span class="toRight">2022-09-20</span>
            </div>
          </li>
        </ul>
      </div>
      <img class="city" src="../assets/img/bottom.png" alt="" />

      <div class="scientificBg">
        <div class="scientific">
          <div class="scientific_title">
            <p>科学科研<span>TEACHING AND RESEARCH</span></p>
            <span class="seeMore">查看更多</span>
          </div>
          <div class="scientific_left">
            <dl>
              <dt>
                <img src="../assets/img/11.jpg" alt="" />
              </dt>
              <dd>
                <p>本科生教育</p>
                <p>UNDERGRADUATE EDUCAYION</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <img src="../assets/img/22.jpg" alt="" />
              </dt>
              <dd>
                <p>研究生教育</p>
                <p>GRADUATE STUDY</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <img src="../assets/img/22.jpg" alt="" />
              </dt>
              <dd>
                <p>科 学 教 育</p>
                <p>SCIENTIFIC RESEARCH</p>
              </dd>
            </dl>
            <dl>
              <dt>
                <img src="../assets/img/22.jpg" alt="" />
              </dt>
              <dd>
                <p>社 会 教 育</p>
                <p>SOCIAL SERVICE</p>
              </dd>
            </dl>
          </div>
          <div class="scientific_right">
            <div>
              <p>本科生教育 <span>详情</span></p>
              <p>
                旅游管理学院的奔了教堂在量具于依靠地位，具有以下几方面的特点：一：办学经验丰富，早在80年代初就开办旅游专业，为国家首批开办本科旅游专业的院校；二：办学特色明显，毕业生具备了扎实的旅游专业基础知识和较强的外语能力，适应我国改革开放对人才的需求旅游管理学院的奔了教堂在量具于依靠地位，具有以下几方面的特点：一：办学经验丰富，早在80年代初就开办旅游专业，为国家首批开办本科旅游专业的院校；二：办学特色明显，毕业生具备了扎实的旅游专业基础知识和较强的外语能力，适应我国改革开放对人才的需求...
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="noTitle_context">
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd style="color: #a12916">
            <h4 class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》
            </h4>
            <p class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登
            </p>
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <h4 class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </h4>
            <p class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </p>
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <h4 class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </h4>
            <p class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </p>
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <h4 class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </h4>
            <p class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </p>
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <h4 class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </h4>
            <p class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </p>
          </dd>
        </dl>
        <dl>
          <dt>
            <p>08</p>
            <p>2022.08</p>
          </dt>
          <dd>
            <h4 class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展》文
            </h4>
            <p class="ellipsis2">
              李嘉珊副院长李嘉珊副院长《助力文化强国建设
              推进文化贸易高质量发展高质量发展》文章刊登光明日报推进文化贸易高质量发展》文章刊登光明日报
            </p>
          </dd>
        </dl>
      </div>
      <div class="trade_union">
        <div class="trends_title">
          <p>党群工会<span>PARTY AND MASS TRADE UNIONS</span></p>
        </div>
        <div class="trade_union_left">
          <img src="../assets/img/1699688655453.jpg" alt="" />
          <h3>2022-09-20</h3>
          <p>
            在民族复兴征程上勇当先锋、倾情奉献
            ————旅游科学学院党委开展庆祝建党101周年主题党日活动庆祝建党101周年主题党日
          </p>
          <button class="button_left"><</button>
          <button class="button_right">></button>
          <ul>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div class="trade_union_right">
          <dl style="background: rgba(212, 13, 13, 0.842); color: #fff">
            <dt>
              <p>02/20</p>
              <p>2020</p>
            </dt>
            <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
          </dl>
          <dl>
            <dt>
              <p>02/20</p>
              <p>2020</p>
            </dt>
            <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
          </dl>
          <dl>
            <dt>
              <p>02/20</p>
              <p>2020</p>
            </dt>
            <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
          </dl>
          <dl>
            <dt>
              <p>02/20</p>
              <p>2020</p>
            </dt>
            <dd>旅科苑召开全体惯培生返校前教育暨疫情防控</dd>
          </dl>
        </div>
        <img class="city" src="../assets/img/1699427795970.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      scroll: "",
      isFirst: false,
      activeIndex: 0,
      play: false,
      dp: null,
      xxjlList: [],
      xsshList: [],
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    toDetail(item) {
      if (item.mapProperties) {
        if (item.mapProperties.erOriginLink) {
          window.open(item.mapProperties.erOriginLink);
        } else {
          this.$router.push({
            name: "newsDetail",
            params: {
              columnId: this.$columnIdFile.homexxsh,
              id: item.id,
            },
          });
        }
      } else {
        this.$router.push({
          name: "newsDetail",
          params: {
            columnId: this.$columnIdFile.homexxsh,
            id: item.id,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.homePage {
  // padding-top: 190px;
  position: relative;
  top: -141px;
  .topBanner {
    width: 100%;
    // height: 800px;
    overflow: hidden;
    position: relative;
  
    .context {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: max-content;
      text-align: center;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 60px;
      color: #ffffff;
    }
    .context p:first-child {
      font-family: FZQTJW;
      font-weight: normal;
      font-size: 90px;
      color: #FFFFFF;
    }
    .context p:last-child{
      margin-top: 30px;
      font-family: FZQTJW;
      font-weight: normal;
      font-size: 30px;
      color: #FFFFFF;
    }
    .context_ul {
      font-weight: 400;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.5);
      line-height: 52px;
      list-style: none;
      float: left;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      position: absolute;
      bottom: 49px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 1;
    }
    .context_ul li {
      float: left;
      margin-left: 70px;
      font-weight: 400;
      font-size: 20px;

      line-height: 52px;
      padding: 0 5px;
      &.active {
        color: #fff;
        border-bottom: 1px solid #fff;
      }
      // &:hover{
      //   color: #fff;
      //   border-bottom: 1px solid #fff;
      // }
    }
    .context_ul li:first-child {
      margin-left: 0;
    }
    .leftImg {
      width: 100%;
      z-index: -1;
      img {
        width: 100%;
        // height: 100%;
      }
    }
  }
  .contentBanner {
    // display: flex;
    // justify-content: center;
    .news_info {
      background: url("../assets/img/xwzx.png") no-repeat;
      background-size: 100% 100%;
      padding: 60px 268px 77px 271px;
      width: 100%;
      overflow: hidden;
    }
    .news_info_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 41px;
    }
    .news_info_title p {
      font-weight: 500;
      font-size: 48px;
      color: #000000;
      /* margin-left: 100px; */
    }
    .news_info_title p span {
      margin-left: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      opacity: 0.4;
    }
    .seeMore {
      font-weight: 400;
      font-size: 18px;
      color: #000000;
      opacity: 0.4;
    }
    .news_info .center {
      display: flex;
      justify-content: space-between;
      .news_info_slide{
        width: 381px;
        margin-top: 13px;
        img{
          width: 100%;
          height: 215px;
          margin-bottom: 30px;
        }
        .news_info_slide_item{
          padding-bottom: 54px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin-bottom: 49px;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .news_info_center{
        width: 545px;
        img{
          width: 545px;
          height: 306px;
        }
      }
    }
  
    .ellipsis2 {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .ellipsis {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
    }
    
    /* --------------------- */
    .notice {
      background: url("../assets/img/bg2.png") no-repeat;
      background-size: 100% 100%;
      padding: 77px 270px 86px 270px;
      width: 100%;
      overflow: hidden;
    }
    .notice_title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 68px;
    }
    .notice_title p {
      font-weight: 500;
      font-size: 48px;
      display: flex;
      align-items: center;
      color: #000000;
    }
    .notice_title p span {
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      margin-left: 12px;
      opacity: 0.4;
    }
    .notice_module {
      display: flex;
      justify-content: space-between;
      .noticeItem {
        width: 322px;
        height: 300px;
        background: #ffffff;
        padding: 0 28px;
        color: #000000;
        &.active {
          color: #fff;
          background: rgba(192, 53, 48, 1);
        }
        .noticeItemDate {
          margin-top: 62px;
          font-family: Gotham;
          font-weight: 600;
          font-size: 30px;
        }
        .times {
          font-family: Gotham;
          font-weight: 500;
          font-size: 16px;
          margin-bottom: 72px;
        }
        .noticeContent {
          font-weight: 400;
          font-size: 18px;

          line-height: 30px;
        }
      }
    }
    .notice_module dl {
      width: 24%;
      height: 250px;
      background: #fff;
    }
    .notice_module dl dt {
      margin: 50px 0 0 40px;
    }
    .notice_module dl dt p:first-child {
      font-size: 30px;
    }
    .notice_module dl dt p:last-child {
      font-size: 18px;
    }
    .notice_module dl dd {
      font-size: 18px;
      margin: 50px 40px 0 40px;
    }
    /* ------------------------ */
    .science {
      width: 100%;
      height: 918px;
      background: #eeeeed;
      // padding-top: 20px;
      position: relative;
      color: #fff;
    }
    .science_title {
      z-index: 11;
      padding: 0 270px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0;
      top: 88px;
      .seeMore {
        color: #ffffff;
        opacity: 0.4;
      }
    }
    .science_title p {
      font-weight: 500;
      font-size: 48px;
      color: #ffffff;
      display: flex;

      align-items: center;
    }
    .science_title p span {
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      opacity: 0.4;
      margin-left: 12px;
      margin-top: 10px;
    }

    .science_piece {
      width: 25%;
      height: 100%;
      float: left;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .science_piece p:nth-child(2) {
      line-height: 53px;
      position: absolute;
      z-index: 1;
      top: 388px;
      left: 225px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 50px;
      color: #ffffff;
    }
    .science_piece p:last-child {
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #ffffff;
      width: max-content;
      opacity: 0.6;
      transform: rotate(90deg);
      transform-origin: 0;
      position: absolute;
      top: 380px;
      left: 289px;
    }
    // .science_piece:nth-child(2) {
    //   background: url('../assets/img/c1.png') no-repeat;
    //   background-size: 100% 100%;

    // }
    // .science_piece:nth-child(3) {
    //   background: url('../assets/img/c2.png') no-repeat;
    //   background-size: 100% 100%;

    // }
    // .science_piece:nth-child(4) {
    //   background: url('../assets/img/c3.png') no-repeat;
    //   background-size: 100% 100%;

    // }
    // .science_piece:nth-child(5) {
    //   background: url('../assets/img/c4.png') no-repeat;
    //   background-size: 100% 100%;

    // }
    /* ------------------------ */
    .scientific {
      width: 70%;
      margin-left: 15%;
      height: 800px;

      padding-top: 20px;
    }
    .scientificBg {
      background: #fefefe;
    }
    .scientific_title {
      height: 100px;
      line-height: 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .scientific_title p {
      width: 400px;
      font-size: 30px;
      /* margin-left: 100px; */
    }
    .scientific_title p span {
      font-size: 16px;
      color: #999;
      margin-left: 10px;
    }
    .scientific_left {
      /* margin-left: 100px; */
      margin-right: 24px;
      width: 450px;
      float: left;
    }
    .scientific_left dl {
      width: 100%;
      height: 150px;
      box-shadow: 1px 1px 3px #ccc;
      display: flex;
    }
    .scientific_left dl:first-child {
      background: #a12916;
      color: white;
    }
    .scientific_left dl dt {
      width: 100px;
      height: 100%;
      text-align: center;
      border-right: 1px solid #f2e3e0;
    }
    .scientific_left dl dt img {
      width: 60px;
      height: 50px;
      margin-top: 50px;
    }
    .scientific_left dl dd p:first-child {
      margin: 50px 0 0 70px;
      font-size: 25px;
    }
    .scientific_left dl dd p:nth-child(2) {
      width: 300px;
      margin: 10px 0 0 70px;
      color: #999;
    }
    .scientific_right {
      float: right;
      height: 600px;
      /* width: 1270px; */
      width: calc(100% - 474px);
      background: url("~@/assets/img/second/1699434203479.png");
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      position: relative;
    }
    .scientific_right div {
      /* width: 1100px; */
      /* height: 150px; */
      position: absolute;
      left: 50px;
      bottom: 20px;
      border-left: 2px solid #fff;
      padding-left: 24px;
    }
    .scientific_right div p:first-child {
      font-size: 30px;
    }
    .scientific_right div span {
      font-size: 20px;
      margin-left: 24px;
      padding: 5px 24px;
      background: white;
      color: #a12916;
      border-radius: 20px;
    }
    .scientific_right div p:nth-child(2) {
      margin-top: 24px;
      font-size: 18px;
    }
    .ellipsis2 {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    /* ----------------------------- */
    .noTitle_context {
      height: 700px;
      padding: 100px 15% 100px 15%;
      background: #e9f0f7;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .noTitle_context dl {
      width: 45%;
      height: 190px;
      border-bottom: 2px solid white;
      float: left;
      /* margin-right: 100px; */
      display: flex;
      padding-top: 50px;
    }
    .noTitle_context dl dt {
      width: 100px;
      height: 120px;
      border: 1px solid;
      border-radius: 10px;
      margin-right: 24px;
    }
    .noTitle_context dl:first-child dt {
      border: 1px solid red;
      background: #a12916;
      color: white;
    }
    .noTitle_context dt p:first-child {
      font-size: 25px;
      text-align: center;
      margin-top: 24px;
    }
    .noTitle_context dt p:nth-child(2) {
      text-align: center;
      width: 100px;
      font-size: 16px;
      margin-top: 5px;
    }
    .noTitle_context dd {
      font-size: 18px;
      width: 650px;
    }
    .noTitle_context dd p {
      margin-top: 24px;
      line-height: 24px;
      font-size: 14px;
    }
    /* ---------------------- */
    .trade_union {
      width: 100%;
      height: 1200px;
      background: #e0e0dd;
      position: relative;
    }
    .trade_union_left {
      margin-left: 15%;
      width: 28%;
      height: 700px;
      position: relative;
      float: left;
    }
    .trade_union_left img {
      width: 100%;
      height: 400px;
    }
    .trade_union_left h3 {
      margin: 24px 0;
    }
    .trade_union_left p {
      font-size: 22px;
      padding-right: 24px;
      line-height: 24px;
    }
    .trade_union_left .button_left {
      width: 40px;
      height: 40px;
      background: #f5e9e7;
      color: #a12916;
      border-radius: 50%;
      border: none;
      font-size: 20px;
      float: left;
      margin-top: 24px;
    }
    .trade_union_left .button_right {
      width: 40px;
      height: 40px;
      background: #f5e9e7;
      color: #a12916;
      border-radius: 50%;
      border: none;
      font-size: 20px;
      float: left;
      margin: 24px 0 0 16px;
    }
    .trade_union_left button:hover {
      background: #a12916;
      color: #fff;
      cursor: pointer;
    }
    .trade_union_left ul {
      list-style: none;
      position: absolute;
      right: 0;
      top: 565px;
    }
    .trade_union_left ul li {
      float: left;
      width: 10px;
      height: 10px;
      background: #f4e7e5;
      margin-left: 10px;
      border-radius: 50%;
    }
    .trade_union_left ul li:first-child {
      background: #a12916;
    }
    .trade_union_right {
      width: 40%;
      height: 700px;
      float: left;
      margin-left: 50px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
    .trade_union_right dl {
      width: 45%;
      height: 260px;
      background: #fff;
      float: left;
      margin: 0 0 30px 0;
    }
    .trade_union_right dl dt {
      margin: 50px 0 0 40px;
    }
    .trade_union_right dl dt p:first-child {
      font-size: 30px;
    }
    .trade_union_right dl dt p:last-child {
    }
    .trade_union_right dl dd {
      margin: 50px 40px 0 40px;
      font-size: 22px;
    }
    .city {
      background: #fff;

      width: 100%;
    }
    /* ----------------------------------- */
    .media {
      background: url("../assets/img/xwzx.png") no-repeat;
      background-size: 100% 100%;
      padding: 90px 268px 0px 271px;
      width: 100%;
      overflow: hidden;
    }
    .media_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 49px;
    }
    .media_title p {
      font-weight: 500;
      font-size: 48px;
      color: #000000;
    }
    .media_title p span {
      margin-left: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #000000;
      opacity: 0.4;
    }
    .media ul {
      list-style: none;
      /* margin-left: 100px; */
      /* margin-right: 100px; */
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
    }
    .media ul li {
      width: 680px;
      height: 185px;
      background: #ffffff;
      box-shadow: 0px 4px 68px 0px rgba(145, 155, 171, 0.13);
      border-radius: 8px;
      margin-bottom: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #333333;
      line-height: 35px;
      padding: 35px 26px;
      &.active {
        color: #ffffff;
        background: #c03530;
        .title {
          color: #ffffff;
        }
        .bottom {
          color: #ffffff;
          .toRight {
            color: #ffffff;
          }
        }
      }
      .title {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #c03530;
        margin-bottom: 40px;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #333333;
        .toRight {
          font-size: 20px;
          color: #666666;
        }
      }
    }

    .city {
      width: 100%;
    }
  }
}
</style>
